<template>
  <div class="page_bg">
    <div>
      <van-image width="10rem" height="7rem"
                 src="https://oss.xiaoyi120.com/shop2.0/home/1.png"
      />
      <van-image width="10rem" height="5rem"
                 src="https://oss.xiaoyi120.com/shop2.0/home/2.png"
      />
      <van-image width="10rem" height="8rem"
                 src="https://oss.xiaoyi120.com/shop2.0/home/3.jpg"
      />
    </div>
    <div class="mask flex-r-c-c">
      <div class="certification_dialog flex-c-c-c">
        <van-image width="4.11rem" height="2.75rem"
                   src="https://oss.xiaoyi120.com/shop2.0/home/certification.png"
        />
        <div class="certification_title"> 您尚未提交认证资料 </div>
        <div class="certification_tip"> 暂时无法采购 </div>
        <div class="flex-r-sa-c">
          <van-button class="loginOut_btn" round type="info" plain color="#0767ab" @click="loginOut">重新登录
          </van-button>
          <van-button class="certification_btn" round type="info" color="#0767ab" @click="toUpCertification">立即认证
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { kickOutByToken } from '@/api/user'
export default {
  name: 'PreCertification',
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {
    // 去认证
    toUpCertification() { this.$router.push('/home/certification') },

    loginOut() {
      kickOutByToken().then(res => { this.$utils.localLogout(this) })
    }
  }
}
</script>

<style lang="scss">
.mask {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 10rem;
  height: 22rem;
}
.certification_dialog {
  margin-top: -3rem;
  width: 9rem;
  height: 8.8rem;
  background: linear-gradient(180deg, #d4e9fa 0%, #ffffff 100%);
  border-radius: 0.27rem;

  .certification_title {
    font-size: 0.48rem;
    font-weight: bold;
    line-height: 0.81rem;
    color: #333333;

    margin-top: 0.56rem;
  }
  .certification_tip {
    font-size: 0.37rem;
    color: #999999;

    margin-top: 0.05rem;
  }
  .loginOut_btn{
    width: 2.8rem;
    height: 1.17rem;
    opacity: 1;
    border-radius: 0.59rem;

    margin-top: 0.69rem;
    margin-right: 0.69rem;
  }
  .certification_btn {
    width: 2.8rem;
    height: 1.17rem;
    opacity: 1;
    border-radius: 0.59rem;

    margin-top: 0.69rem;
  }
}
</style>
